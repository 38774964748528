import './App.css';

import styled, { createGlobalStyle } from 'styled-components';
import Metsi from '../src/images/metsi.jpg';
import Metsi2 from '../src/images/metsi2.jpg';
import { Helmet } from 'react-helmet';
import { Link, Element } from 'react-scroll';

const GlobalStyle = createGlobalStyle`
  :root {
    --toastify-color-info: #67B9FF;
    --toastify-color-success: #6DF238;
    --toastify-color-error: #DE2323;
    --toastify-color-warning: #FDC633;

    --toastify-font-family: 'SF Pro', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  }

  html {
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: visible;
    font-size: min(1vw, 15px);
    background-color: #fff;
  }
  
  body {
    font-family: 'SF Pro', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    padding: 0;
    margin: auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    overflow: visible;
  }

  #root {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .grecaptcha-badge { visibility: hidden; }
`;

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`;

const Outer = styled.div<{ contrast?: boolean; }>`
  width: 100%;
  background-color: ${({ contrast }) => (contrast ? '#172A7C' : '#fff')};
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`;

const Topbar = styled.div`
  width: calc(100rem - 10.28rem);
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  height: 5.3rem;
  padding: 0 5.14rem;
`;

const Button = styled.button`
  font-size: 2.11rem;
  line-height: 2.11rem;
  outline: none;
  margin: 0;
  margin-right: 5.3rem;
  cursor: pointer;
  border: none;
  color: #fff;
  background: none;
`;

const Hero = styled.div`
  width: calc(100rem - 10.28rem);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 33rem;
  background-color: #fff;
  padding: 0 5.14rem;
`;

const Title = styled.h1`
  margin: 0;
  font-size: 10rem;
  font-weight: 700;
  color: #172A7C;
  border: 2px solid #172A7C;
  padding: 1.58rem 4.2rem;
  border-radius: 6.6rem; 
`;

const HeroImage = styled.img`
  width: 34.39rem;
  height: 32.7rem;
`;

const Contract = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 16rem;
  background-color: #172A7C;
  font-size: 2.6rem;
  font-weight: 700;
  color: #fff;
`;

const ContractLink = styled.a`
  margin-left: 0.5rem;
  color: #fff;
  text-decoration: none;
`;

const ImageWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  padding: 5.3rem 0;
`;

const Image = styled.img`
  width: 85.97rem;
  height: 48.34rem;
`;

const Why = styled.div`
  width: calc(100rem - 13.2rem);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 6.6rem;
  background-color: #172A7C;
`;

const WhyTitle = styled.h2`
  font-size: 10rem;
  font-weight: 700;
  color: #fff;
  margin: 0;
  margin-bottom: 4.5rem;
`;

const WhyText = styled.p`
  font-size: 3.3rem;
  font-weight: 400;
  color: #fff;
  margin: 0;
  margin-bottom: 1.8rem;
  align-self: flex-start;
`;

const ChartWrapper = styled.div`
  width: calc(100% - 13.2rem);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  padding: 5.3rem 6.6rem;
`;

const ChartTitle = styled.h3`
  font-size: 5.3rem;
  font-weight: 700;
  color: #172A7C;
  margin: 0;
  margin-bottom: 3.3rem;
`;

const BottomBar = styled.div`
  width: 100rem;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  height: 6.3rem;
  background-color: #172A7C;
`;

const BottomButton = styled.a`
  font-size: 3rem;
  line-height: 3rem;
  outline: none;
  margin: 0;
  cursor: pointer;
  border: none;
  color: #fff;
  background: none;
  text-decoration: none;
`;

const App = () => (
  <>
      <Helmet>
        <title>$metsi</title>
      </Helmet>
      <GlobalStyle />
      <Wrapper>
        <Outer contrast>
          <Topbar>
            <Button>$metsi</Button>
            <Link to="why" smooth={true} duration={500}>
              <Button>why</Button>
            </Link>
            <Link to="buy" smooth={true} duration={500}>
              <Button>buy</Button>
            </Link>
          </Topbar>
        </Outer>
        <Outer>
          <Hero>
            <Title>loe $metsi</Title>
            <HeroImage src={Metsi} alt="$metsi" />
          </Hero>
        </Outer>
        <Contract>
          <u>Contract:</u>
          <ContractLink href="https://solscan.io/token/HxcR83wd2xi4mZzGX5JnmLa4NUJ7PddBeQM3yWDBvxTj" target="_blank" rel="noopener noreferrer">HxcR83wd2xi4mZzGX5JnmLa4NUJ7PddBeQM3yWDBvxTj</ContractLink>
        </Contract>
        <ImageWrapper>
          <Image src={Metsi2} alt="$metsi" />
        </ImageWrapper>
        <Element name="why" style={{ width: '100%' }}>
          <Outer contrast>
            <Why>
              <WhyTitle>why <u>$metsi</u>?</WhyTitle>
              <WhyText>• baaaaa baaaa baaaa baaaaaaaaa baaaaaaaaaa</WhyText>
              <WhyText>• becaus gota 🐐</WhyText>
              <WhyText>• becaus fuck geri gensler</WhyText>
            </Why>
          </Outer>
        </Element>
        <Element name="buy" style={{ width: '100%' }}>
          <ChartWrapper>
            <ChartTitle>buy $metsi</ChartTitle>
            <div id="dexscreener-embed">
              <iframe title="$metsi" src="https://dexscreener.com/solana/cesyrdgcdvttw3zbqudevxtznwawtlsxdglwephnutxh?embed=1&theme=dark&trades=0"></iframe>
            </div>
          </ChartWrapper>
        </Element>
        <Outer contrast>
          <BottomBar>
            <BottomButton>telegram</BottomButton>
            <BottomButton>twitter</BottomButton>
            <BottomButton href="https://solscan.io/token/HxcR83wd2xi4mZzGX5JnmLa4NUJ7PddBeQM3yWDBvxTj" target="_blank" rel="noopener noreferrer">contract</BottomButton>
            <BottomButton href="https://dexscreener.com/solana/cesyrdgcdvttw3zbqudevxtznwawtlsxdglwephnutxh" target="_blank" rel="noopener noreferrer">chart</BottomButton>
          </BottomBar>
        </Outer>
      </Wrapper>
    </>
)

export default App;
